(function(){
    angular
        .module('app')
        .directive('ngGoogleplace', googleplace);
        googleplace.$inject = ['$timeout','$document','uiGmapGoogleMapApi'];
        function googleplace($timeout,$document,GoogleMapApi){
            return {
            	require: 'ngModel',
		        restrict: 'A',
		        scope: {
		            onchangeplace: '&',
		            country: '='
		        },
		        link: function (scope, element, attrs,model) {
		        	return GoogleMapApi.then((function(_this) {
		        		return function(maps) {
		        			scope.$watch('country', function(newValue, oldValue) {
		        				var options = {
					                types: [],
					                componentRestrictions: { country:newValue }
					            };
					            scope.gPlace = new google.maps.places.Autocomplete(element[0], options);

					            google.maps.event.addListener(scope.gPlace, 'place_changed', function() {
					                scope.$apply(function() {
					                    model.$setViewValue(element.val());    
					                    scope.onchangeplace();            
					                });

					            });
				            });
							
				        }
			        })(this));

		        }
		    }
       }
})();