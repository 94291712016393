(function() {
    'use strict';

    angular
        .module('app',['ngSanitize','uiGmapgoogle-maps'])
        .config(['uiGmapGoogleMapApiProvider', function (GoogleMapApi) {
		  GoogleMapApi.configure({
		    key: 'AIzaSyBIP2vkKS_gH9zt-caouszTFfy1BSj_5tk',
		    // v: '3.20',
		    libraries: 'geometry,places',
		    language:'es-es'
		  });
		}])
        /*.config(function (GoogleMapApi) {
		    GoogleMapApi.configure({
		        key: 'AIzaSyBXyePuSW1AX9rBA1EQ6P-TYwo_yHrZ_d4',
		        v: '3.17',
		        libraries: 'weather,geometry,visualization'
		    });
		});*/
})();
