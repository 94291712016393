(function(){
    'use strict';
   
    angular
    .module('app')
    .controller('MapController', MapController)
 
    MapController.$inject = ["LocalizadorService","$http","$timeout","$log", "$document","$sce","$scope","uiGmapGoogleMapApi"];
   
    function MapController(LocalizadorService,$http,$timeout, $log, $document,$sce,$scope,GoogleMapApi)
    {                  
        //console.log("E");      
        var vm = this; 
        vm.icon = "/img/i_marker.png?v=1";
        vm.iconiai = "/img/i_markeriai.png?v=1";
        vm.cluster = "/img/i_cluster.png?v=1";
        vm.pais = "";
        vm.esiai = "todos";
        vm.zip="";
        vm.provincia = "";
        vm.countrycode = 'co';
        vm.especialidad = {
          s1:false,
          s2:false,
          s3:false,
          s4:false
        };

        vm.localizado = false;

        vm.contadorVisibles = 0;

        vm.mapEvents = {
          bounds_changed:function (map) {
            return GoogleMapApi.then((function(_this) {
                return function(maps) {

                  var bounds = new google.maps.LatLngBounds();
                  vm.contadorVisibles = 0;
                  for(var cont=0;cont<vm.centros.length;cont++){
                      vm.centros[cont].visible = false;
                      var latlong = new google.maps.LatLng(Number(vm.centros[cont].lat), Number(vm.centros[cont].long));
                      if(
                        vm.centros[cont].country == vm.pais && map.getBounds().contains(latlong) && 
                        ((((vm.esiai=="iai" && vm.centros[cont].esiai) || vm.esiai=="todos") 
                        && !vm.especialidad.s1 
                        && !vm.especialidad.s2 
                        && !vm.especialidad.s3 
                        && !vm.especialidad.s4)
                        || 
                        (
                          ((vm.esiai=="iai" && vm.centros[cont].esiai) || (vm.esiai=="todos")) 
                          &&
                          ((vm.especialidad.s1 && vm.mirarIai(vm.centros[cont],"Infantil")) 
                          || (vm.especialidad.s2 && vm.mirarIai(vm.centros[cont],"Acúfenos")) 
                          || (vm.especialidad.s3 && vm.mirarIai(vm.centros[cont],"Implantes")) 
                          || (vm.especialidad.s4 && vm.mirarIai(vm.centros[cont],"Reeducación")))
                        ))
                        ){
                        vm.centros[cont].visible = true;
                        vm.contadorVisibles++;
                      }
                  }
                }
            })(this));
          }

        };
        var istouch = "ontouchstart" in document.documentElement;
        vm.map = { 
            center: { latitude: '41.3850639', longitude: '2.1734034' }, 
            zoom: 8,
            clusterOptions: {
              minimumClusterSize : 6,
              zoomOnClick: true,
              styles: [{
                  url: vm.cluster,
                  width:32,
                  height:44,
                  textColor: 'black',
                  textSize: 13
              }],
              averageCenter: true,
              clusterClass: 'cluster-icon'
            },
            options:{
              gestureHandling: (istouch ?  'cooperative':'greedy'),
              styles: [
                {
                  "featureType": "poi",
                  "stylers": [{"visibility": "off"}] 
                },
              ]
            },
            markers: [{id:1,coords:{latitude:45,longitude:-73}}],
            window: {
              marker: {},
              show: false,
              closeClick: function() {
                this.show = false;
              },
              model:{
                centro:{
                  id: 0,
                  address: "test"
                }
              },
              options: {
              } 
            }
        };

        vm.markers = [],

        vm.coords = {};
        vm.radio = 10000;

        vm.centros = [];
        vm.bnds = "";

        vm.address = "";

        vm.changeCoords = function(lat_gm,long_gm){
          vm.coords.lat = lat_gm;
          vm.coords.long = long_gm;
        }

        vm.cambiarIai = function(iai){
          vm.esiai = iai;
        }

        vm.getLatitudeLongitude = function(callback) {
          return GoogleMapApi.then((function(_this) {
                return function(maps) {
                  var address = vm.address || vm.country;
                  var geocoder = new google.maps.Geocoder();
                  if (geocoder) {
                      geocoder.geocode({
                          'address': address,
                          'componentRestrictions':{'country':vm.countrycode}
                      }, function (results, status) {
                          if (status == google.maps.GeocoderStatus.OK) {
                              callback(results[0]);
                          }
                      });
                  }
                }
            })(this));
        }

        vm.changePais = function(country,code,lat_gm,long_gm){
          vm.map.window.show = false;
          vm.pais=country;
          vm.address='';
          vm.countrycode=code;
          vm.radio = 10000;
          vm.changeCoords(lat_gm,long_gm);
          vm.getCenterCentros();
        }

        vm.centrar = function(lat_gm,long_gm){
          vm.map.center = { latitude: lat_gm, longitude: long_gm };
          vm.map.zoom = 15;
        }

        vm.buscar = function(){
            if(vm.address==""){
                vm.radio=10000;
            }else{
                vm.radio = 3.5;
            }
            vm.getLatitudeLongitude(function(result){
                vm.coords.lat = result.geometry.location.lat();
                vm.coords.long = result.geometry.location.lng();
                vm.getCenterCentros();
            });
        }

        vm.centrarMapa = function(lat,lng,zoom){
          //var pos = new google.maps.LatLng(lat,lng)
          vm.map.window.show = false;
          vm.map.zoom = zoom;
          vm.map.center = {
            latitude: lat,
            longitude: lng
          };
        }

        vm.goScroll = function(centro){
          $(".scroll").animate({
            scrollTop: $(".scroll").scrollTop()+$(".store_"+centro.id).position().top-40
          }, 800);
        }

        vm.markersEvents = {
          click: function (gMarker, eventName, model) {
            if(model.centro!=""){
              $timeout(function(){
                vm.map.window.model = model;
                vm.map.window.show = true;
                vm.goScroll(model.centro);
              })
            }
          }
        };

        vm.localizacionActual = function(){
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(objPosition)
            {
              var lon = objPosition.coords.longitude;
              var lat = objPosition.coords.latitude;
              vm.coords.lat = lat;
              vm.coords.long = lon;

              var geocoder = new google.maps.Geocoder();
              var latlng = new google.maps.LatLng(lat, lon);
              geocoder.geocode({'latLng': latlng}, function(results, status) {

              var calle = "";
              var num = "";
              var zip = "";
              var city = "";
              if (status == google.maps.GeocoderStatus.OK) {
                  //if (results[1]) {
                  if (results[0]) {
                    for (var i=0; i<results[0].address_components.length; i++) {
                      for (var b=0;b<results[0].address_components[i].types.length;b++) {
                        //console.log(results[0].address_components[i]);
                        if (results[0].address_components[i].types[b] == "route") {
                          calle += results[0].address_components[i].long_name;
                        }
                         if (results[0].address_components[i].types[b] == "street_number") {
                          num += " "+results[0].address_components[i].long_name;
                        }
                        if (results[0].address_components[i].types[b] == "locality") {
                          city += ", "+results[0].address_components[i].long_name;
                        }
                        else if (results[0].address_components[i].types[b] == "postal_code") {
                          zip += ", "+results[0].address_components[i].long_name;
                        }
                      }
                    }
                    vm.address = calle + num + city + zip;
                  }
                }
                vm.radio = 1.5;
                vm.localizado = true;
                vm.getCenterCentros();
              });
            });
            
          }else{
            //console.log("No hay permiso");
          }
          
        }

        vm.windowParams = {
          test: function() {
          },
          window:vm.map.window
        }

        vm.mirarIai = function(centro,especialidad){
          //s1 Audiologia infantil
          /* delegs
            Infantil
            Acúfenos
            Implantes
            Reeducación
          */
          var res = false;
          if(
            centro.especialidad1==especialidad 
            || centro.especialidad2==especialidad 
            || centro.especialidad3==especialidad 
            || centro.especialidad4==especialidad)
            res = true;
          return res;
        } 

        vm.getCenterCentros = function(){
          return GoogleMapApi.then((function(_this) {
                return function(maps) {
                    vm.contadorVisibles = 0;
                    //console.log(vm.especialidad);

                    var centros = vm.centros;
                    $timeout(function(){
                        vm.markers = [];
                        var bounds = new google.maps.LatLngBounds();
                        var markersTotal = [];
                        var contadorVisible = false;
                        var actual = new google.maps.LatLng(Number(vm.coords.lat), Number(vm.coords.long));
                        var contador = 1;
                        for(var cont=0;cont<centros.length;cont++){
                            if(
                                (
                                  ((vm.esiai=="iai" && centros[cont].esiai) || (vm.esiai=="todos")) 
                                  && !vm.especialidad.s1 && !vm.especialidad.s2 && !vm.especialidad.s3 && !vm.especialidad.s4
                                ) 
                                || 
                                (
                                  ((vm.esiai=="iai" && centros[cont].esiai) || (vm.esiai=="todos")) 
                                  &&
                                  ((vm.especialidad.s1 && vm.mirarIai(centros[cont],"Infantil")) 
                                  || (vm.especialidad.s2 && vm.mirarIai(centros[cont],"Acúfenos")) 
                                  || (vm.especialidad.s3 && vm.mirarIai(centros[cont],"Implantes")) 
                                  || (vm.especialidad.s4 && vm.mirarIai(centros[cont],"Reeducación")))
                                )
                              ){
                              centros[cont].marker = true;
                            }else{
                              centros[cont].marker = false;
                            }
                            var myLatLng = new google.maps.LatLng(Number(centros[cont].lat), Number(centros[cont].long));
                            var distance = google.maps.geometry.spherical.computeDistanceBetween(actual, myLatLng);

                            centros[cont].distance = google.maps.geometry.spherical.computeDistanceBetween(actual, myLatLng);
                            centros[cont].contador = contador;
                            var ret = {
                              latitude: Number(centros[cont].lat),
                              longitude: Number(centros[cont].long),
                              title: 'm' + cont,
                              distance : distance,
                              centro:centros[cont],
                              show: false,
                              icon: vm.icon//(centros[cont].esiai ? vm.iconiai : vm.icon)
                            };
                            ret["id"] = cont;

                            if(centros[cont].marker && centros[cont].country == vm.pais){
                              markersTotal.push(ret);
                              contador++;
                            }

                            
                            centros[cont].visible = false;

                            //1000metros
                            if(centros[cont].marker && centros[cont].country == vm.pais && parseInt(distance) <= (vm.radio*1000)){
                              centros[cont].visible = true;
                              vm.contadorVisibles++;

                              contadorVisible = true;
                              bounds.extend(myLatLng);
                              vm.bnds = {
                                  northeast: {
                                      latitude: bounds.getNorthEast().lat(),
                                      longitude: bounds.getNorthEast().lng()
                                  },
                                  southwest: {
                                      latitude: bounds.getSouthWest().lat(),
                                      longitude: bounds.getSouthWest().lng()
                                  }
                              }
                            }
                        }
                        if(!contadorVisible){
                          if(vm.radio<10000){
                            vm.radio+=2;
                            vm.getCenterCentros();
                          }else{
                            vm.busquedaError = true;
                          }
                        }else{
                          // PONEMOS LA DIRECCIÓN DE LA PERSONA en caso de que haya una direccion puesta
                          //COMENTADO POR PETICIÓN DE GAES.
                          if(vm.address){
                            var ret = {
                              latitude: Number(vm.coords.lat),
                              longitude: Number(vm.coords.long),
                              title: 'm' + cont,
                              centro:"",
                              show: false,
                              icon: '/img/point.png?v=1'
                            };
                            ret["id"] = cont;
                            markersTotal.push(ret);
                            bounds.extend(actual);
                            vm.bnds = {
                                northeast: {
                                    latitude: bounds.getNorthEast().lat(),
                                    longitude: bounds.getNorthEast().lng()
                                },
                                southwest: {
                                    latitude: bounds.getSouthWest().lat(),
                                    longitude: bounds.getSouthWest().lng()
                                }
                            }
                          }
                          vm.markers = markersTotal;
                        }
                    })
                }
          })(this));
        }


        vm.setMarkersDireccion = function(){
            LocalizadorService.getTiendas(vm.pais,vm.provincia,vm.coords,vm.radio,function(data){
                //console.log(data);
                if(data.centros.length>0){
                  vm.centros = data.centros;
                  vm.getCenterCentros();
                  if (navigator.geolocation)
                    vm.localizacionActual();
                }else{
                    if(vm.radio<10000){
                        vm.radio+=10;
                        vm.setMarkersDireccion();
                    }else{
                      vm.centros = [];
                      vm.markers = [];
                    }
                }
                
            });
        }

    }

})();